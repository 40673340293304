@font-face {
  font-family: "retro-chimps";
  src: url("./assets/font/retro-chimps.otf");
}
@font-face {
  font-family: "ca-spotnik";
  src: url("./assets/font/CASpotnik-Medium.otf");
}
@font-face {
  font-family: "ca-spotnik-bold";
  src: url("./assets/font/CASpotnik-ExtraBold.otf");
}

/* Apply base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Customize base styles */
@layer base {
  h1 {
    @apply fluid-4xl;
    font-family: "retro-chimps";
  }
  h2 {
    @apply fluid-3xl;
    font-family: "retro-chimps";
  }
  h3 {
    @apply fluid-2xl;
    font-family: "ca-spotnik-bold";
  }
  h4 {
    @apply fluid-xl;
    font-family: "ca-spotnik-bold";
  }
  h5 {
    @apply fluid-lg;
    font-family: "ca-spotnik-bold";
  }
  p {
    @apply fluid-base;
    font-family: "ca-spotnik";
  }
  a {
    @apply fluid-base;
    font-family: "ca-spotnik-bold";
  }
}

/* Apply styles to specific elements */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Set global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hero-after {
  content: "";
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/7/76/1k_Dissolve_Noise_Texture.png");
  height: 300%;
  width: 300%;
  position: fixed;
  opacity: 0.05;
  animation: animateGrain 8s steps(10) infinite;
  pointer-events: none;
}

@keyframes animateGrain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, -20%);
  }
  30% {
    transform: translate(-5%, -10%);
  }
  40% {
    transform: translate(-15%, -20%);
  }
  50% {
    transform: translate(-5%, -10%);
  }
  60% {
    transform: translate(-15%, -20%);
  }
  70% {
    transform: translate(-5%, -10%);
  }
  80% {
    transform: translate(-15%, -20%);
  }
  90% {
    transform: translate(-5%, -10%);
  }
  100% {
    transform: translate(-15%, -20%);
  }
}
